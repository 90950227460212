import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { toStyleObj, safePrefix, htmlToReact } from "../utils";

export default class Page extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <article className="post post-full">
          <header className="post-header has-gradient outer">
            {_.get(this.props, "pageContext.frontmatter.image") && (
              <div
                className="bg-img"
                style={toStyleObj(
                  "background-image: url('" +
                    safePrefix(
                      _.get(this.props, "pageContext.frontmatter.image")
                    ) +
                    "')"
                )}
              />
            )}
            <div className="inner-sm">
              <h1 className="post-title">
                {_.get(this.props, "pageContext.frontmatter.title")}
              </h1>
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
            </div>
          </header>
          <div className="inner-md outer">
            <div className="post-content">
              {htmlToReact(_.get(this.props, "pageContext.html"))}
            </div>
          </div>
        </article>
      </Layout>
    );
  }
}
